
@font-face {
  font-family: 'roboregular';
  src: url('../public/fonts/robo-webfont.woff') format('woff2'),
       url('../public/fonts/robo-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'robotobold';
  src: url('../public/fonts/roboto-bold-webfont.woff2') format('woff2'),
       url('../public/fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'robotoregular';
  src: url('../public/fonts/roboto-regular-webfont.woff2') format('woff2'),
       url('../public/fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'robotomedium';
  src: url('../public/fonts/roboto-medium-webfont.woff2') format('woff2'),
       url('../public/fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
body{
  margin: 0;
  padding: 0;
  font-family: 'roboregular' !important;
}
*{
  box-sizing: border-box;
}
a, a:hover, button{
  outline: none;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #6A7798;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0D84FB;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0875e2;
}
button:disabled{
  cursor: not-allowed;
}
#root{
  background: transparent radial-gradient(closest-side at 50% 50%, #253F8C 0%, #12202D 100%) 0% 0% no-repeat padding-box;
  min-height: 100vh;
}
.appWrp {
  padding: 0 15px;
}
.header {
  display: flex;
  justify-content: space-between;
}

.currentStatusRankingWrp h3 {
  color: #fff;
  text-align: center;
}

.currentStatusRanking {
  position: relative;
  margin-top: 110px;
  margin-right: 120px;
}

.currentStatusBg {
  min-width: 480px;
  background: url(/public/images/progress-bg.png) 0 0 no-repeat;
  height: 35px;
  background-size: 100% 100% !important;
  position: relative;
  z-index: 9;
}
.currentStatusProfile img {
  width: 45px;
  border: solid 2px #00fff6;
  border-radius: 50%;
  height: 45px;
  object-fit: cover;
}

.currentStatusPin {
  position: absolute;
  top: -110px;
  text-align: center;
  /* left: calc(100%/8*2 - 24px); */
}

.currentStatusProfile {
  position: relative;
  top: 10px;
}

.currentStatusCup {
  position: absolute;
  right: -60px;
  top: -50px;
  z-index: 0;
}

.currentStatusCup img {
  width: 60px;
}
.downloadCertificate {
  text-align: right;
  position: relative;
  z-index: 9;
  margin-top: 30px;
  left: 50px;
}
.downloadCertificate > button{
  background: url(../public/images/download-certificate.png) 0 0 no-repeat;
  background-size: 100% 100%;
  border: none;
  padding: 8px 6px;
}
.downloadCertificate button span{
  background: url(../public/images/download-bg.png) 0 0 repeat;
  display: block;
  color: #0D84FB;
  padding: 13px 30px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'roboregular';
  font-size: 11px;
}
.certificateDropMenuWrp {
  position: absolute;
  right: 0;
  top: 100%;
  background: rgb(105,127,178);
  background: linear-gradient(209deg, rgba(105,127,178,1) 0%, rgba(120,144,197,1) 31%, rgba(92,166,233,1) 100%);
  padding: 8px;
  border-radius: 5px;
  padding-bottom: 15px;
}
.closeBtn {
  position: absolute;
  right: 10px;
  bottom: -13px;
}
.certificateDropMenu span {
  max-width: 105px;
  display: inline-block;
  font-size: 11px;
  color: #fff;
  text-align: left;
  line-height: 1.4;
}

.certificateDropMenu {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  background: url(../public/images/horizontal-line.png) bottom left no-repeat;
  background-size: 100% 2px;
}
/* .certificateDropMenu:nth-child(odd){
  background: transparent;
} */
.certificateDropMenu:nth-last-child(2){
  background: transparent;
}
.certificateDropMenu .btnType1 {
  padding: 10px 20px;
  font-size: 10px;
}
.statusLine {
  position: absolute;
  top: -20px;
  left: calc(100%/8);
  z-index: 9;
}
.statusLine:nth-child(2) {
  position: absolute;
  top: -20px;
  left: calc(100%/8*2);
}
.statusLine:nth-child(3) {
  position: absolute;
  top: -20px;
  left: calc(100%/8*3);
}
.statusLine:nth-child(4) {
  position: absolute;
  top: -20px;
  left: calc(100%/8*4);
}
.statusLine:nth-child(5) {
  position: absolute;
  top: -20px;
  left: calc(100%/8*5);
}
.statusLine:nth-child(6) {
  position: absolute;
  top: -20px;
  left: calc(100%/8*6);
}
.statusLine:nth-child(7) {
  position: absolute;
  top: -20px;
  left: calc(100%/8*7);
}
.currentStatusRankingWrp {
  position: relative;
  top: 70px;
}
.statusLine img {
  height: 50px;
}
.statusPercent {
  background: rgb(38,172,255);
  background: linear-gradient(180deg, rgba(38,172,255,1) 0%, rgba(145,212,255,1) 35%, rgba(255,255,255,1) 100%);
  height: 25px;
  margin-left: 3px;
  border-radius: 7px 0 0 7px;
  position: absolute;
  top: 3px;
  z-index: 1;
}
.profileName span {
  position: absolute;
  left: 50%;
  top: 14px;
  transform: translate(-50%);
  color: #fff;
  font-size: 11px;
}
.profileImageFrame img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.profileImageFrame {
  position: absolute;
  left: 0;
  top: 9px;
}
.profileImageFrame{
  background: url(../public/images/profile-frame.png) 0 0 no-repeat;
  padding: 10px;
  background-size: 100% 100%;
}
.profileDeatils {
  position: relative;
  padding-top: 60px;
}
.profileDropmenuWrp {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background: url(../public/images/profile-dropmenu-frame.png) 0 0 no-repeat #082469;
  background-size: 100% 100%;
  padding: 13px;
  min-width: 190px;
  z-index: 9;
}
/* .profileDropmenuWrp {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);

} */

.profileDropmenu {
  display: flex;
  gap: 7px;
  color: #fff;
  font-size: 10px;
  margin: 7px 0;
  align-items: center;
  padding: 10px;
  background: #414E62;
  border-radius: 5px;
  width: 100%;
  border: none;
  font-family: 'roboregular';
}

.profileDropmenu > img {
  height: 16px;
}
.profileNameWrp {
  position: relative;
}
.profileName{
  position: relative;
  z-index: 9;
}
.profileName > img {
  width: 300px;
}
.profileAngleDown {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 35px;
  z-index: 2;
}
.profileAngleDown img {
  width: 60px;
}
.profileDropmenuBtns {
  position: absolute;
  bottom: -31px;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 13px;
}
.midSection {
  display: flex;
  justify-content: space-between;
  /* gap: 15px; */
}

.leftSidebar {
  width: 30%;
}

.rightSideBar {
  width: 35%;
}
.headingFrame {
  display: inline-block;
  background: url(../public/images/heading-frame.png) 0 0 no-repeat;
  background-size: 100% 100% !important;
  padding: 20px 30px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  z-index: 2;
  letter-spacing: 1px;
}
.importantUpdatesContent{
  background-image: url(../public/images/updates-frame.png);
  background-size: 100% 100%;
  padding: 20px;
  color: #fff;
  position: relative;
  top: -8px;
  z-index: 1;
  background-repeat: no-repeat;
  font-size: 10px;
  line-height: 1.6;
  font-family: 'robotoregular';
}
.updateDateHeader, .UpdateRow {
  display: flex;
}

.updateDate {
  width: 150px;
  padding: 10px;
  background: #414E62;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'robotobold';
}

.updateCon {
  flex: calc(100% - 150px);
  color: #fff;
  background: #414E62;
  padding:8px 15px;
  font-size: 10px;
  line-height: 1.4;
  font-family: 'robotoregular';
  overflow-wrap: anywhere;
}
.updateCon a {
  color: #00ffcb;
  font-family: 'robotoregular';
}
.updateDateHeader .updateCon {
  text-align:center;
  background: #414E62;
  border-radius: 5px;
  padding: 10px;
  font-family: 'roboregular';
  font-size: 10px;
}

.updateDateHeader {
  border-bottom: solid 10px transparent;
}

.updateDateHeader .updateDate {
  margin-right: 20px;
  border-radius: 5px;
  font-family: 'roboregular';
  font-size: 10px;
}
.UpdateContentWrp {
  padding: 10px 0;
  max-height: 290px;
  overflow: auto;
  background: #414E62;
  border-radius: 5px;
}
.eventRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background: url(../public/images/horizontal-line.png) bottom left no-repeat;
  background-size: 100% 2px;
}
.upcomingEvents{
  margin-top: 20px;
}
.eventsList {
  background: #414E62;
  padding:0 10px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}
.eventRow:last-child{
  background: transparent;
}
.eventRow h3 {
  margin: 0;
  color: #fff;
  font-size: 11px;
}
.eventBtn{
  padding: 13px 20px;
  background: url(../public/images/event-btn-frame.png) 0 0 no-repeat;
  border: none;
  outline: none;
  background-size: 100% 100%;
  color: #0D84FB;
  min-width: 130px;
  text-align: center;
  display: block;
  font-size: 9px;
  font-family: 'roboregular';
  color: #0D84FB;
}
.eventBtn:disabled{
  background: url(../public/images/event-btn-frame-disable.png) 0 0 no-repeat;
  color:#ddd7d8a1 ;
  background-size: 100% 100%;
}
.midSectionWrp {
  min-width: 500px;
  padding-top: 120px;
}

.videoWrp {
  background: #273D73;
  padding: 20px 10px 30px;
  border-radius: 20px;
}

.videoWrp h1 {
  margin: 0;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 3px 3px 2px #00000029;
  font-size: 24px;
  margin: 0 0 20px;
}
.videoHeading{
  text-align: center;
  color: #fff;
  background: url(../public/images/video-heading-bg.png) top center no-repeat;
  padding: 13px;
  font-size: 16px;
  background-size: 280px 100%;
  margin-bottom: -5px;
  font-family: 'robotoregular';
}

.videoBx {
  position: relative;
}

.videoBx > img {
  width: 500px;
  /* min-width: 600px; */
}

.videoUrl {
  position: absolute;
  width: 79%;
  top: 47%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.videoBtns {
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 45px;
  align-items: center;
}
.btnType1, .btnType2{
  background: url(../public/images/video-btn-1.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 8px 30px;
  border: none;
  font-family: 'roboregular';
  color: #0D84FB;
  font-size: 9px;
  line-height: 1.6;
  white-space: nowrap;
}
.btnType2{
  background: url(../public/images/btn-type-2.png) 0 0 no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.profileDropmenuBtns .btnType1, .profileDropmenuBtns .btnType2{
  padding: 10px 20px;
    font-size: 11px;
}
.videoBtn2{
  background: url(../public/images/video-btn-2.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 18px 35px;
  border: none;
  font-family: 'roboregular';
  color: #0D84FB;
  font-size: 12px;
  line-height: 1.6;
  white-space: nowrap;
}
.videoBtn2:disabled {
  filter: brightness(0.6);
}
[data-tooltip] {
	position: relative;
	cursor: help;
	font-family: serif;
}
[data-tooltip]:before {
	content: attr(data-tooltip);
	display: none;
	position: absolute;
	background: #fff;
	color: #000;
	box-shadow: 0 0 20px 5px rgb(0 0 0 / 20%);
	padding: 4px 8px;
	font-size: 12px;
	line-height: 1.4;
	min-width: 150px;
	text-align: center;
	border-radius: 4px;
	font-weight: 300;
  top: 100%;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	display: block;
	z-index: 50;
}
.rightSideBar h2 {
  margin: 0 0 20px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 3px 3px 2px #00000029;
  font-size: 28px;
}

.hackathonTableHeadingRow, .hackathonTableBodyRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.hackathonTableCell, .hackathonTableBodyCell {
  padding:5px 7px;
  background: #414E62;
  color: #fff;
  font-size: 9px;
  text-align: center;
  width: 100%;
  font-family: 'robotoregular';
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 73px;
}

.hackathonTableBodyCell h4 {
  margin: 0;
  font-family: 'robotoregular';
  font-weight: normal;
  white-space: nowrap;
  font-size: 10px;
}
.hackathonTableCell:nth-child(2), .hackathonTableBodyCell:nth-child(2) {
  min-width: 108px;
}
.hackathonTableCell:first-child, .hackathonTableBodyCell:first-child {
  min-width: 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hackathonTableBodyCell:first-child h4{
  max-width: 105px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.hackathonTableHeadingRow .hackathonTableCell:first-child{
  justify-content: center;
}
.hackathonTableHeadingRow .hackathonTableCell{
  font-family: 'roboregular';
  padding: 10px;
  font-size: 9px;
  border-radius: 5px;
}
.hackathonTableHeadingRow{
  margin-bottom: 10px;
}
.hackathonTableBodyCell button {
  border: none;
  color: #49de00;
  font-size: 10px;
  padding: 6px 10px;
  background: #687591;
  border-radius: 5px;
}

.hackathonTableBodyCell button:disabled {
  color: #959595;
  background: transparent;
}
.hackathonTable{
  background: url(../public/images/hackathon-table-frame.png) 0 0 no-repeat;
  padding: 25px;
  background-size: 100% 100%;
  padding-bottom: 40px;
}
.hackathonTableWrp {
  height: 400px;
  overflow: auto;
}
.conceptBtn {
  position: relative;
}

.conceptDropMenuWrp {
  position: absolute;
  bottom: 100%;
  left: -5px;
  background: rgb(105,127,178);
  background: linear-gradient(209deg, rgba(105,127,178,1) 0%, rgba(120,144,197,1) 31%, rgba(92,166,233,1) 100%);
  padding: 8px;
  border-radius: 5px;
  max-height: 210px;
  overflow: auto;
}

.conceptDropMenu a, .conceptDropMenu button {
  font-size: 10px;
  white-space: nowrap;
  color: #fff;
  display: block;
  padding: 5px;
  border-radius: 5px;
  font-family: 'roboregular';
  background: transparent;
  border: none;
}

.conceptDropMenu a:hover, .conceptDropMenu button:hover {
  background: #02378E;
}
.popup {width: 450px;background: rgb(95,163,223);background: radial-gradient(circle, rgba(95,163,223,1) 0%, rgba(154,171,199,1) 49%, rgba(112,129,164,1) 100%);padding: 15px;border-radius: 10px; position: relative;}
.popupHeader {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  text-align: center;
}

.popupHeader h3 {
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;
}

.closePopup {
  position: absolute;
  right: 10px;
  top: 10px;
}

.closePopup img {
  width: 20px;
}
.popupWrp {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: #0003;
}
.popupBody{
  background: url(../public/images/profile-popup-frame.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 40px 20px 20px;
}
.formLabel label {
  background: url(../public/images/download-bg.png) 0 0 repeat;
  background-size: 100% 100%;
  border: none;
  padding: 8px 6px;
  color: #0D84FB;
  font-size: 11px;
  min-width: 140px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
}
.formGroup {
  margin: 0 0 10px;
}

.formInput {
  position: relative;
}

.formInput input {
  width: 100%;
  padding: 12px 20px;
  background: #ffffff2e;
  border: none;
  display: block;
  color: #fff;
  font-size: 11px;
  font-family: 'roboregular';
  padding-right: 60px;
  border-radius: 10px;
  text-transform: uppercase;
}

.formInput button {
  position: absolute;
  right: 0;
  top: 4px;
  background: transparent;
  border: none;
  outline: none;
}

.formInput button img {
  width: 45px;
}
.formSubmit{
  text-align: right;
}
.formSubmit button{
  background: transparent;
  border: none;
  outline: none;
}
.formSubmit button img {
  width: 100px;
}
.footer{
  background: #ffffff2e;
  padding: 5px 15px;
  margin-top: 20px;
}
.footer a{
  color: #fff;
  font-size: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer img{
  height: 16px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000075;
  z-index: 9999;
}

.loader img {
  height: 50px;
}
.errorMsg {
  font-size: 9px;
  color: red;
  margin-top: 5px;
}




















/*=====================media-query-start=========================*/
@media only screen and (max-width:1366px) and (min-width:1200px){
  .rightSideBar{
    width: 32%;
  }
  .hackathonTableBodyCell h4 {
    white-space: normal;
  }
  .currentStatusBg{
    min-width: 350px;
  }
  .currentStatusCup img{
    width: 60px;
  }
  .leftSidebar{
    width: 28%;
  }
  .midSectionWrp {
      min-width: 400px;
  }

  .videoBx > img {
      width: 400px;
  }
  .currentStatusCup {
    top: -40px;
    right: -60px;
  }

  .midSectionWrp {
      min-width: 400px;
  }

  .videoBx > img {
      width: 400px;
  }

  .btnType1 {
      padding: 8px 15px;
      font-size: 7px;
  }

  .videoBtn2 {
      padding: 13px 25px;
      font-size: 11px;
  }

  .videoBtns {
      bottom: -11px;
      padding: 0 30px;
      left: 8px;
  }

  .hackathonTableCell:first-child, .hackathonTableBodyCell:first-child {
      min-width: 110px;
      gap: 5px;
  }

  .hackathonTableCell:nth-child(2), .hackathonTableBodyCell:nth-child(2) {
      min-width: 40px;
  }

  .hackathonTableCell, .hackathonTableBodyCell {
      min-width: 60px;
  }
  .hackathonTableCell {
      font-size: 8px !important;
  }
}
@media only screen and (max-width:1199px) and (min-width:992px){
  .midSection{
    flex-wrap: wrap;
  }
  .leftSidebar {
    width: 43%;
  }

  .rightSideBar {
    width: 60%;
    margin-top: 30px;
  }
  .currentStatusBg{
    min-width: 300px;
  }
  .currentStatusCup {
    top: -40px;
    right: -60px;
  }
  .currentStatusCup img{
    width: 55px;
  }
  .statusPercent {
    height: 20px;
    top: 6px;
  }
  .downloadCertificate {
      margin-top: 10px;
      margin-bottom: 50px;
  }

}
/* @media only screen and (max-width:991px) and (min-width:768px){ */
@media only screen and (max-width:991px){
  .currentStatusRankingWrp {
    order: 3;
    top: 0;
      margin: 0 auto;
  }

  .header {
      flex-wrap: wrap;
  }
  .midSection{
    flex-wrap: wrap;
  }
  .leftSidebar {
    width: 100%;
  }

  .rightSideBar {
    width: 100%;
    margin-top: 30px;
  }
  .currentStatusBg{
    min-width: 450px;
  }
  .currentStatusCup {
    top: -45px;
    right: -60px;
  }
  .currentStatusCup img{
    width: 55px;
  }
  .statusPercent {
    height: 20px;
    top: 6px;
  }
  .downloadCertificate {
      margin-top: 10px;
      margin-bottom: 50px;
  }
  .midSectionWrp{
    padding-top: 30px;
    margin: 0 auto;
  }
  .currentStatusRankingWrp{
    padding-top: 50px;
  }
  .statusLine img{
    height: 44px;
  }
}
@media only screen and (max-width:600px){
  .brandLogo img {
    width: 110px;
  }
  .popup {
    width: 90%;
  }
  .currentStatusBg {
      min-width: 269px;
  }

  .currentStatusRankingWrp {
      width: 100%;
      padding-right: 40px;
  }

  .profileImageFrame img {
      width: 50px;
      height: 50px;
  }

  .profileImageFrame {
    top: 18px;
    left: 0px;
  }

  .profileName > img {
      width: 200px;
  }

  .profileName span {
      font-size: 9px;
      top: 10px;
  }

  .midSectionWrp {
      min-width: 300px;
  }

  .videoBx > img {
      width: 300px;
  }

  .btnType1, .btnType2 {
      padding: 8px 8px;
  }

  .currentStatusRankingWrp h3 {
      font-size: 14px;
  }

  .currentStatusCup img {
      width: 50px;
  }

  .currentStatusCup {
      top: -40px;
      right: -50px;
  }

  .videoBtn2 {
      padding: 10px 20px;
      font-size: 11px;
  }

  .videoBtns {
      padding: 0;
  }

  .currentStatusRanking {
      margin-right: 0;
  }
  .statusPercent{
    height: 18px;
  }
  .videoUrl iframe{
    height: 120px ;
  }
  .profileAngleDown {
    top: 18px;
  }
  .videoWrp {
    padding: 20px 5px;
  }

  .videoWrp h1 {
    font-size: 20px;
  }
}
.pay-msg {
	font-family: 'robotoregular';
}
